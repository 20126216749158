import { get, post, getuser } from "./request";

export const getList = (params) => get("/media.php", params); //获取素材
export const deleteList = (params) => post("/media_delete.php", params); //删除
export const beforeUpload = (params) => post("/media_exist.php", params); //上传之前判断是否重复
export const editName = (params) => post("/media_name_edit.php", params); //修改素材名
export const mediaCount = (params) => post("/media_counts.php", params); //获取素材总数
export const getScene = (params) => get("/get_solo_scene.php", params); //获取单个用户场景信息
export const getUserInfo = (params) =>
  getuser("https://api.khfw430.com/api/user/profile/userInfo", params); //通过token获取用户信息
export const searchScene = (params) => post("/media_search.php", params); //搜索素材
export const modifyModel = (params) => get("/template_update.php", params); //修改模型
export const checkScene = (params) => get("/check_solo_scene.php", params); //验证场景用户信息
export const scene_update = (params) => post("/scene_update.php", params); //改变场景
export const scene_psd = (params) => post("/scene_psd.php", params); //设置展厅访问密码
export const get_scene_interacts = (params) => post("/get_scene_interacts.php", params); //设置展厅访问密码
export const get_solo_media = (params) => get('/get_solo_media',params)
export const set_scene_thumbs = (params) => get('/set_scene_thumbs',params)
export const set_scene_comment = (params) => get('/set_scene_comment',params)
export const get_scene_user = (params) => get('/get_scene_user', params)
