<template>
  <div>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <div class="box" v-if="flag" id="box">
        <div>
          <img
            :src="list[value].url ? list[value].url : ''"
            v-if="list.length > 0 && checkFlag(list[value])"
            class="img"
            id="img"
            :style="
              'transform:' +
              'scale(' +
              scale +
              ');left:' +
              position.left +
              'px;' +
              'right:' +
              position.right +
              'px;' +
              'top:' +
              position.top +
              'px;' +
              'bottom:' +
              position.bottom +
              'px;'
            "
          />
          <div
            v-if="list.length > 0 && checkFlag(list[value])"
            class="img"
            :style="
              'transform:' +
              'scale(' +
              scale +
              ');left:' +
              position.left +
              'px;' +
              'right:' +
              position.right +
              'px;' +
              'top:' +
              position.top +
              'px;' +
              'bottom:' +
              position.bottom +
              'px;'
            "
            @mousedown="start"
          >
            <audio autoplay :src="audio" />
          </div>
          <!-- <video
            v-if="list.length > 0 && !checkFlag(list[value])"
            :src="list[value].url"
            controls
            loop
            autoplay
          ></video> -->

          <video-player
            v-if="list.length > 0 && !checkFlag(list[value])"
            class="video-player vjs-custom-skin video"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
          >
          </video-player>
        </div>
        <div class="function" v-if="list.length > 0 && checkFlag(list[value])">
          <div v-for="(item, index) in functionBox" :key="index" @click="f(index)">
            <img :src="item.img" />
            <p>{{ item.font }}</p>
          </div>
        </div>

        <img src="../assets/img/close.png" @click="close" class="icon" />
        <div class="title" v-if="title">{{ title }}</div>
        <div class="title title1" v-if="list.length > 0">
          <!-- <p>{{ list[value].name }}</p> -->
          <p>{{ value + 1 }}/{{ list.length }}</p>
        </div>
        <div class="text" v-if="text">
          {{ text }}
        </div>

        <img
          src="../assets/img/bg-left.png"
          class="left"
          v-if="value > 0 && list.length > 0"
          @click="value--"
        />
        <img
          src="../assets/img/bg-right.png"
          class="right"
          v-if="value < list.length - 1 && list.length > 0"
          @click="value++"
        />

        <iframe
          :src="link"
          frameborder="0"
          style="width: 80%; height: 80%; margin: 5% 10%"
          v-if="iframeFlag"
        ></iframe>
      </div>
    </transition>
  </div>
</template>

<script>
import Bus from "../../common/js/bus";

export default {
  data() {
    return {
      touch: false,
      flag: false,
      flag1: false,
      vedio: "",
      vedioFlag: false,
      img: "",
      imgFlag: false,
      scale: 1,
      functionBox: [
        {
          font: "放大",
          img: require("../assets/img/big.png"),
        },
        {
          font: "缩小",
          img: require("../assets/img/small.png"),
        },
        {
          font: "还原",
          img: require("../assets/img/re.png"),
        },
      ],
      position: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      moveobj: {},
      title: "",
      text: "",
      audio: "",
      list: [],
      value: 0,
      iframeFlag: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //倍速控制
        autoplay: true, //是否自动播放
        muted: false, //是否静音播放
        loop: false, //是否循环播放
        preload: "auto",
        language: "zh-CN", //语言，还要引入对应的文件
        aspectRatio: "16:9", //比例
        fluid: true,
        sources: [
          {
            type: "",
            src: "", //url地址
          },
        ],
        poster: "", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: {
          timeDivider: true,
          durationDisplay: true, //剩余时间是否显示
          remainingTimeDisplay: false, //剩余时间是否显示，有一个即可
          fullscreenToggle: true, //全屏按钮
        },
      },
      link: "",
    };
  },
  methods: {
    close() {
      this.flag = false;
      this.vedioFlag = false;
      this.imgFlag = false;
      this.iframeFlag = false;
      this.flag1 = false;
      this.scale = 1;
      console.log("关闭");
      this.value = 0;
      this.position = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      };
      Bus.$emit("music", true);
    },
    f(e) {
      var scale = this.scale;
      if (e == 0) {
        this.scale = scale * 1.2;
      } else if (e == 1) {
        this.scale = scale * 0.8;
      } else {
        this.scale = 1;
        this.position = {
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        };
      }
    },
    start(e) {
      console.log(e);
      document.body.addEventListener("mousemove", this.move);
      document.body.addEventListener("mouseup", this.end);

      document.body.addEventListener("touchmove", this.move);
      document.body.addEventListener("touchend", this.end);
    },
    move(e) {
      this.moveobj = e;
    },
    end() {
      this.moveobj = {};
      document.body.removeEventListener("mousemove", this.move);
      document.body.removeEventListener("mouseup", this.end);
      document.body.addEventListener("touchmove", this.move);
      document.body.addEventListener("touchend", this.end);
    },
    changeBox() {
      this.flag1 = true;
      setTimeout(() => {
        document.getElementById("box").style.cssText =
          "width: 100%;height: 70px;background-color: transparent;position: absolute;left: 0;top: 0;z-index: 999;";
      }, 1);
      console.log("进入浏览");
    },
    checkFlag(e) {
      if (e.name.split(".")[1] != "mp4") {
        return true;
      } else {
        return false;
      }
    },
  },
  created() {
    Bus.$on("point", (target) => {
      console.log(target);
      Bus.$emit("music", false);
      this.position = {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      };
      //展板1
      this.flag = true;
      this.imgFlag = true;
      this.iframeFlag = false;
      this.list = target.res_list;
      console.log(this.list);
      if (!this.checkFlag(this.list[this.value])) {
        this.playerOptions.sources[0].src = this.list[this.value].url;
      }
      if (target.text) {
        this.text = document.showroom.utf8to16(document.showroom.decode64(target.text));
      } else {
        this.text = "";
      }

      if (target.title) {
        this.title = document.showroom.utf8to16(document.showroom.decode64(target.title));
      } else {
        this.title = "";
      }

      if (target.voice_res) {
        this.audio = target.voice_res.url;
      }
    });

    Bus.$on("hypherLink", (target) => {
      this.imgFlag = false;
      if (target.Hyperlinks) {
        var link = document.showroom.utf8to16(
          document.showroom.decode64(target.Hyperlinks)
        );

        function CheckUrl(a) {
          var regstr = /http/; //var reg = new RegExp("^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$");
          console.log(regstr.test(a));
          if (regstr.test(a)) {
            return a;
          } else {
            return `https://${a}`;
          }
        }
        window.open(CheckUrl(link));
      }
    });
  },
  watch: {
    flag(val) {
      Bus.$emit("flag", val);
    },
    moveobj: {
      handler(newValue, oldValue) {
        if (!this.touch && newValue.clientX && oldValue.clientX) {
          this.position.top = this.position.top + newValue.clientY - oldValue.clientY;
          this.position.left = this.position.left + newValue.clientX - oldValue.clientX;
        }

        if (this.touch && newValue.touches[0].clientX && oldValue.touches[0].clientX) {
          this.position.top =
            this.position.top + newValue.touches[0].clientY - oldValue.touches[0].clientY;
          this.position.left =
            this.position.left +
            newValue.touches[0].clientX -
            oldValue.touches[0].clientX;
        }
      },
      deep: true,
    },
    value(val) {
      if (!this.checkFlag(this.list[val])) {
        this.playerOptions.sources[0].src = this.list[val].url;
      }
    },
  },
  mounted() {
    // document.addEventListener("wheel", this.onMouseWheel, false);
  },
};
</script>

<style scoped lang="scss">
.box::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

.box {
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.box {
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000, $alpha: 0.53);
  position: absolute;
  left: 0;
  top: 0;
  z-index: 999;

  .img {
    position: absolute;
    height: 680px;
    margin: auto;
    cursor: move;
    // transition: 0.5s;
  }
  .icon {
    top: 20px;
    right: 30px;
    margin: auto;
    position: fixed;
    width: 30px;
    height: 30px;
  }
  .bigicon {
    top: 5%;
    right: 0;
    left: 0;
    margin: auto;
    position: fixed;
    width: 40px;
    height: 40px;
  }
}
.function {
  width: 180px;
  background-color: rgba($color: #eee, $alpha: 0.5);
  color: #333;
  border-radius: 20px;
  font-size: 12px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 100px;
  img {
    width: 20px;
  }
}
.title {
  position: absolute;
  top: 0;
  background-color: rgba($color: #000, $alpha: 0.53);
  color: white;
  text-align: center;
  padding: 10px 20px;
  left: 50%;
  transform: translate(-50%, 0);
}
.title1 {
  top: 50px;
  padding: 5px 10px;
}

.text {
  position: absolute;
  height: 85px;
  width: 70%;
  overflow-y: scroll;
  bottom: 0;
  background-color: rgba($color: #000, $alpha: 0.53);
  color: white;
  text-align: center;
  padding: 0px 20px;
  left: 0;
  right: 0;
  margin: auto;
}
.left {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 80px;
  width: 80px;
}
.right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 80px;
  width: 80px;
}
.video {
  width: 60% !important;

  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 200px;
}
// @media screen and (max-width: 450px) {
//   video {
//     width: 100% !important;
//   }
//   .img {
//     width: 100%;
//     height: auto;
//   }
// }

@media screen and (max-width: 450px) {
  .box {
    .img {
      width: 100%;
      height: auto;
    }
  }
  .right {
    width: 40px;
    right: 40px;
  }
  .left {
    width: 40px;
    left: 40px;
  }
  .function {
    display: none;
  }

  .video {
    width: 100% !important;
    height: auto;
    top: 200px !important;
  }
}

@media screen and (max-width: 1500px) {
  .box {
    .img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
