<template>
  <div id="app">
    <main-view />
    <o2vr />
    <Box />
    <!-- <HandShake :touch="flag" /> -->
  </div>
</template>
<script>
import MainView from "./components/MainView.vue";
import o2vr from "../common/components/o2vr.vue";
import Box from "../showroom/components/box.vue";
import HandShake from "./components/handShake.vue";
export default {
  components: {
    o2vr,
    MainView,
    HandShake,
    Box,
  },
  data() {
    return {
      flag: false,
    };
  },
  created() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      this.flag = true;
    }

    // document.addEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
    // document.removeEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
  },
};
</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
input {
  -webkit-user-select: auto; /*webkit浏览器*/
}
textarea {
  -webkit-user-select: auto; /*webkit浏览器*/
}

#app {
  height: 100vh;
  overflow: hidden;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
::-webkit-scrollbar {
  width: 2px;
} /*滚动条宽度设置：宽度为0 ，当有滚动条出现的时候则不显示滚动条*/

::-webkit-scrollbar-track {
  background-color: #2b303b;
} /*滚动条槽背景设置*/

::-webkit-scrollbar-thumb {
  background-color: #444343;
  border-radius: 5px;
} /*滚动条滑块颜色设置*/
</style>
