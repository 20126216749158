import axios from "axios";
import QS from "qs";

// axios.defaults.baseURL = "http://localhost/vryun/php/";
axios.defaults.baseURL = "https://o2vr.net/vryun/php/";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";
// axios.defaults.headers.post['token'] = "76faf1a8f39fece2bc988612ddff11c340bca638fce2e508c5bb82d398a0639d"

export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        params: params,
      })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, QS.stringify(params))
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export function getuser(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(
        url,
        {
          params: params,
        },
        {
          headers: {
            "XX-Token": localStorage.getItem("token"),
            "XX-Device-Type": "pc",
          },
        }
      )
      .then((res) => {
        if (res.data.code == 10001) {
          // alert("登录已失效");
          // window.location.href = "http://o2vr.net/upload/3D_editor/testLogin/";
        } else resolve(res.data);
      })
      .catch((err) => {
        reject(err.data);
      });
  });
}

export default axios.create({
  baseURL: "",
  timeout: 10000,
});
