import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import { o2vr } from "../common/js/o2";
import VueAudio from "vue-audio";
import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);
Vue.use(VueAudio);
import { showroom } from '../common/js/showroom'
Vue.prototype.Showroom = new showroom()
document.showroom = Vue.prototype.Showroom;
// import { o2builder } from '../common/js/o2_builder'
import "animate.css/animate.compat.css"
Vue.config.productionTip = false;
Vue.prototype.O2vr = new o2vr();
document.o2vr = Vue.prototype.O2vr;
document.o2 = Vue.prototype.O2vr;
document.showroom.o2 = document.o2vr;
Vue.use(ElementUI);
// Vue.prototype.O2Builder = new o2builder()
Vue.config.productionTip = false;

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
